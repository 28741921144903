'use client';

import { usePathname, useRouter } from 'next/navigation';

import { ReactNode, useCallback, useEffect, useMemo } from 'react';

import { QueryObserverResult, useQueryClient } from '@tanstack/react-query';

import Cookies from 'js-cookie';
import { useIsClient } from 'usehooks-ts';

import { useLoginTestUserMutation } from '@query';

import { DeserializedUser, KycStatus, WalletInterface } from '@api';

import { logout } from '@shared/api/logout';
import { storageKeys } from '@shared/constants';
import clearSavedConnectionDetails from '@shared/helpers/web3/clearSavedConnectionDetails';
import {
  invalidateSessionRelatedMarkedQueries,
  SessionData,
  useSession,
} from '@shared/hooks/query/session';

import { createSafeContext, useSafeContext } from '@utilities/context';

export type ContextValue = {
  isAuth: boolean;
  isLoading: boolean;
  logout: (callback?: () => void) => void;
  refreshSession: () => Promise<QueryObserverResult<SessionData, Error>>;
  userEvmWallet?: WalletInterface;
  kycResultStatus: KycStatus;
} & (
  | {
      isAuth: true;
      user: DeserializedUser;
    }
  | {
      isAuth: false;
      user: undefined | null;
    }
);

const Context = createSafeContext<ContextValue>();

export const useAuth = ({ redirectUnauth }: { redirectUnauth?: boolean } = {}) => {
  const context = useSafeContext(Context);
  const router = useRouter();

  const { user, isLoading } = context;

  Cookies.set('isPartner', JSON.stringify(user?.attributes.isPartner));

  useEffect(() => {
    if (redirectUnauth && !isLoading && !user) {
      router.push('/');
    }
  }, [isLoading, user, redirectUnauth]);

  return context;
};

// NOTE: not sure if it is the right place for this hook and if naming suits
export const useIsCurrentDeveloper = (developerId: string) => {
  const { user, isLoading } = useAuth();

  return useMemo(
    () => !isLoading && developerId === user?.attributes.developerNameId,
    [isLoading, user?.attributes?.developerNameId, developerId],
  );
};

interface AuthProviderProps {
  children?: ReactNode;
  onLogout?: () => void;
}

const TEST_USER_ID = 1;

export const useAuthUsingTestUser = () => {
  const { isPending: isLoading, mutateAsync } = useLoginTestUserMutation();
  const login = useCallback(() => mutateAsync({ id: TEST_USER_ID }), [mutateAsync]);

  return {
    isLoading,
    login,
  };
};

export const logoutSession = async () => {
  try {
    await logout();
    // we should clear cookies after we clear quires data, to prevent logoutClient function to be called twice
    // because we subscribe window.cookieStore on change event and call logoutClient again
    Cookies.remove('Authorized');
    Cookies.remove(storageKeys.LOGGED_INTO_MAGIC_BOOST);
    clearSavedConnectionDetails();
  } catch (error) {
    // noop
  }
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const router = useRouter();
  const isClient = useIsClient();
  const {
    session,
    query: sessionQuery,
    clearSessionRelatedData,
    prepareSessionForLogout,
  } = useSession();
  const { isFetching, refetch } = sessionQuery;

  const isLoading = !isClient || isFetching;

  const isAuth = !!session.user;
  const value = useMemo(() => {
    const kyc = session.user?.attributes.kyc;
    return {
      isLoading,
      refreshSession: () => refetch(),
      isAuth,
      user: session.user,
      userEvmWallet: session.user?.wallets.find((wallet) => wallet.attributes.network === 'EVM'),
      kycResultStatus: kyc?.sumSub ?? kyc?.babt ? KycStatus.COMPLETED : KycStatus.INIT,
      logout: logoutSession,
    } as ContextValue;
  }, [isAuth, session.user, isLoading, refetch]);

  // TODO: come up with better solution
  useEffect(() => {
    window.__logoutClient = logoutSession;
  }, []);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    invalidateSessionRelatedMarkedQueries(queryClient);
  }, [isAuth, queryClient]);

  // clear user data only on home page to prevent cases when user
  // data suddenly disappear on pages where we use user data (profile pages)
  useEffect(() => {
    if (!session.isExpired) {
      return;
    }

    const isProtectedPathname = ['/spaces', '/magic-boost', '/profile'].some((path) =>
      pathname.startsWith(path),
    );

    // check if it's safe place to remove user data without sudden ui changes
    if (isProtectedPathname) {
      router.replace('/');

      return;
    }

    clearSessionRelatedData();
  }, [pathname, session.isExpired, clearSessionRelatedData]);

  useEffect(() => {
    if (
      !session.user ||
      // logout is already in process we don't need to listed to cookies anymore
      session.isExpired
    ) {
      return;
    }

    const handleCookieStoreChange = (event: { deleted: { name: string }[] }) => {
      const deletedAuthorizedCookie = event.deleted.find(
        (deleted) => deleted.name === 'Authorized',
      );

      if (deletedAuthorizedCookie) {
        prepareSessionForLogout();
      }
    };

    window?.cookieStore?.addEventListener('change', handleCookieStoreChange);

    return () => {
      window?.cookieStore?.removeEventListener('change', handleCookieStoreChange);
    };
  }, [router, session.user, prepareSessionForLogout]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default AuthProvider;
